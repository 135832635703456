<template>
	<div ref="student" class="student">
    <list-template :current-page="page" :loading="loading" :table-config="tableConfig" :table-data="tableData"
                   :total="total" @onChangePage="changeCurrentPage" @onExportData='onExportData' @onHandle="handleEdit">
			<template v-slot:title>
				<el-form ref="form" :model="form" label-width="0" style="padding-right: 40rem">
					<div class="flex flex-align">
						<el-form-item>
							<el-select v-model="form.stages_status" :popper-append-to-body="false" placeholder="筛选收款进度" size="small" @enter="onSearch">
								<el-option label="全部" value="0"></el-option>
								<el-option label="已交清" value="1"></el-option>
								<el-option label="未交清" value="2"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-input v-model="form.phone" placeholder="搜索签约手机号" size="small" @enter="onSearch"></el-input>
						</el-form-item>
						<el-form-item>
							<el-input v-model="form.keyword" placeholder="搜索学生姓名" size="small" @enter="onSearch"></el-input>
						</el-form-item>
						<el-button debounce size="small" type="primary" @click.prevent="onSearch">搜索</el-button>
						<el-button debounce size="small" style="margin-left: 16rem" @click.prevent="reset">重置</el-button>
            <!--						<el-button debounce size="small" type="primary" @click.prevent="onExportData">导出数据</el-button>-->
					</div>
				</el-form>
			</template>
		</list-template>
	</div>
</template>

<script>
import ListTemplate from '@/components/pages/List'
import {mapState} from 'vuex'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
		return {
			loading: true,
			schoolConfig: [],
			// 表格搜索配置
			form: {
				customer_name: '',
				stages_status: '0',
				phone: '',
			},
			// 表格配置
			tableConfig: [
				{prop: 'stages_status', label: '收款进度'},
				{prop: 'customer_phone', label: '家长签约手机号'},
				{prop: 'customer_name', label: '家长姓名'},
				{prop: 'student_name', label: '学生姓名'},
				{prop: 'goods_pack_name', label: '购买商品名称'},
				{prop: 'money_answer', label: '应付金额'},
				{prop: 'money_actual', label: '已收金额'},
				{prop: 'residue_money', label: '剩余金额'},
				{
					label: '操作',
					width: 90,
					handle: true,
					showOverflowTooltip: false,
					render: () => {
						return ['查看']
					}
				}
			],
			// 总数据量
			total: 0,
			// 表格中的数据
			tableData: [],
		}
	},
	computed: {
		...mapState(['page'])
	},
	components: {
		ListTemplate
	},
	mounted() {
		this.$store.commit('setPage', 1)
		this.getData();
	},
	methods: {
		// 分页被更改
		changeCurrentPage(e) {
			this.$store.commit('setPage', e)
			this.getData()
		},
		// 获取数据
		getData() {
			let {form, page} = this
			this.loading = true;
			this.$_register.get('api/recruit-v2/pay/stages-list', {params: {...form, page}}).then(res => {
				let data = res.data.data
				if (Array.isArray(data)) {
					this.tableData = []
					this.total = 0
				} else {
					this.tableData = data.list
					this.total = data.page.total
				}
			}).finally(() => this.loading = false)
		},
		// 搜索功能
		onSearch() {
			this.$store.commit('setPage', 1)
			this.getData()
		},

		//重置搜索框
		reset() {
			Object.keys(this.form).forEach(key => {
				if (key === 'stages_status') this.form[key] = '0'
				else this.form[key] = ''
			})
			this.$store.commit('setPage', 1)
			this.getData()
		},

		// 导出数据
		onExportData() {
			this.$_register.get('/api/recruit-v2/pay/stages-list', {params: Object.assign({}, this.form, {export: 1}), showError: false}).then(res => {
				this.$tools.download('收款进度', res.data)
			})
		},

		handleEdit(row) {
			this.$router.push('./look?id=' + row.id)
		},

	}
}
</script>

<style scoped lang="scss">
::v-deep .el-form .el-input {
	//width: auto !important;
}

::v-deep .el-form-item {
	margin-right: 20rem !important;
	margin-bottom: 12rem !important;
}

::v-deep .el-button--small {
	padding: 9rem 15rem;
	font-size: 12rem;
	border-radius: 3rem;
}

::v-deep .el-button--small {
	height: 32rem;
	margin-bottom: 12rem;
}
</style>
